
import Vue from "vue";

import http from "@/http";
import { Snackbar } from "@/interfaces";

export interface DataType {
  name: string;
  files: File[];
}

export default Vue.extend({
  components: {},
  data(): DataType {
    return {
      name: "",
      files: [],
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/",
        },
        {
          text: "ドキュメント",
          disabled: false,
          to: "/documents",
        },
        {
          text: "アップロード",
          disabled: true,
          to: "/document/create",
        },
      ];
    },
    disabled(): boolean {
      return this.name.length > 0 && this.files.length > 0;
    },
  },
  methods: {
    async click() {
      const formData = new FormData();

      if (this.files.length === 0) {
        return;
      }

      const file = this.files[0];

      formData.append("name", this.name);
      formData.append("file", file);

      const headers = {
        "Content-Type": "multipart/form-data",
      };

      try {
        const res = await http.post("/documents", formData, {
          headers,
        });
        await this.$router.push(`/documents`);
      } catch (error) {
        const snackbar: Snackbar = {
          show: true,
          content: "ファイルに不備があるようです",
          color: "success",
        };

        this.$store.commit("setSnackbar", snackbar);
      }
    },
  },
});
